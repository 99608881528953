import { lazy } from 'react';

import { FirstPage } from 'pages/FirstPage';

export const RestorePopup = lazy(() => import(/* webpackChunkName: "RestorePopup" */'./restore-popup'));

export const RestorePopupPage = () => (
	<>
		<RestorePopup isOpen={true} />
		<FirstPage />
	</>
);
