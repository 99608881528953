import { getCelebration, getPriceWithDiscount } from 'utils/discount';
import { findDocumentByPks } from 'utils/document';
import { asyncRetry, asyncXMLHttpRequest, get } from 'utils/fetch';
import { getPrintOperationsUrl } from 'utils/urls';

import { apiPath } from 'constants/api-path';
import { BASE_DISCOUNT, BASE_PRICE, CELEBRATION_DISCOUNT, PAPER_COUNT_FOR_ORDER } from 'constants/paint-by-numbers';

import { EmptyObject } from 'types/common';
import { Conversations } from 'types/conversation';
import { AnyDocument, Document } from 'types/document';
import { OperationType } from 'types/operation';
import { OptionsColorType, OptionsDoublePageType, Point, PrintForm, Scanner } from 'types/point';
import { OrderForm, PrintOptionsForDocumentsSuccess } from 'types/print';
import { UserBackend } from 'types/user';


export function getPoint(pk: number) {
	return get<Point>(`${apiPath.POINTS}${pk}/`);
}

export function getScanner(pk: number) {
	return get<Scanner>(`${apiPath.SCANNERS.GET}${pk}/`);
}

export function getOrders(currentPage: number, pageSize: number) {
	return get<{ results: OperationType[]; count: number }>(`${apiPath.OPERATIONS}?page=${currentPage}&page_size=${pageSize}`);
}

export function getOrdersWithRetry(
	pageSize: number,
	count = 3,
	nextDelay = 1,
	delay = 0,
): ReturnType<typeof getOrders> {
	if (count === 1) {
		return getOrders(1, pageSize);
	}
	return new Promise((res, rej) => setTimeout(() => {
		getOrders(1, pageSize).then(response => {
			if (response.results.length === 0) {
				return res(getOrdersWithRetry(pageSize, count - 1, delay + nextDelay, nextDelay));
			}
			return res(response);
		}).catch(rej);
	}, delay));
}

export function getPrintCost({ document, printer, copies, color_option, duplex_option }: PrintForm) {
	return get<number>(`/api/user_documents/${document}/get_cost/?document=${document}&printer=${printer}&copies=${copies || 1}&color=${color_option}&duplex=${duplex_option}`);
}

export async function makeOrderFromForm(form: OrderForm): Promise<boolean> {
	try {
		const response = await fetch('/api/print_operations/', {
			headers: {
				'Content-type': 'application/json',
			},
			credentials: 'same-origin',
			method: 'POST',
			body: JSON.stringify(form),
		});
		return response.status === 201;
	} catch {
		return false;
	}
}

type CreatePayment = {
	value: number;
	url: string;
	orders: OrderForm[];
};

export async function createPayment({
	value,
	url,
	orders,
}: CreatePayment): Promise<{return_url: string; confirmation_token: string}> {
	const data = {
		is_embedded:  false,
		order_sum: value,
		return_url: url,
		orders,
	};
	const response = await fetch('/api/orders/robokassa_pay/', {
		headers: {
			'Content-type': 'application/json',
		},
		credentials: 'same-origin',
		method: 'POST',
		body: JSON.stringify(data),
	});
	const { pay_url, return_url } = await response.json();
	return { confirmation_token: pay_url, return_url };
}

export async function createCopyPayment(point_id: number, order_sum: number, copies: number, checkout_token: string | null, employee_id: string | null):
	Promise<{return_url: string; confirmation_token: string}> {
	const data = {
		point_id,
		order_sum,
		copies,
		checkout_token,
		employee_id,
		return_url: getPrintOperationsUrl([]),
	};
	const response = await fetch('/api/orders/start_copy_payment/', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});
	if (!response.ok) {
		throw new Error(`Error: ${response.status} ${response.statusText}`);
	}
	const { pay_url, return_url } = await response.json();
	return { confirmation_token: pay_url, return_url };
}

export async function findDocuments(pks: number[], documents: AnyDocument[]): Promise<Document[] | null> {
	const foundDocuments = documents ? findDocumentByPks(documents, pks) : [];
	if (foundDocuments.length > 0) {
		return foundDocuments;
	}
	try {
		return await Promise.all(pks.map((pk) => get<Document>(`${apiPath.DOCUMENTS}${pk}/`)));
	} catch {
		return null;
	}
}

export function postFeedback(
	feedback_rating: number,
	feedback_theme: string,
	print_order_id: string, // external_id
	feedback_message?: string,
	feedback_file?: File,
) {
	const data = new FormData();
	data.append('feedback_rating', feedback_rating.toString());
	data.append('feedback_theme', feedback_theme);
	data.append('print_order_id', print_order_id);
	feedback_message && data.append('feedback_message', feedback_message);
	feedback_file && data.append('feedback_file', feedback_file);
	
	return asyncXMLHttpRequest(data, apiPath.FEEDBACK);
}

export async function createPrintOperations(optionsForDocuments: PrintOptionsForDocumentsSuccess, balance: number): Promise<{
	errorDocuments: string[];
	confirmation_token: string | null;
	toPay: number;
}> {
	let currentBalance = balance;
	const errorDocuments: string[] = [];
	const orderFormsToPay: OrderForm[] = [];
	const documentPks: number[] = [];
	const optionsForDocument = Object.values(optionsForDocuments);
	for (const { document, options, printerPk, price } of optionsForDocument) {
		documentPks.push(document.pk);
		const data: OrderForm = {
			document: document.pk,
			printer: printerPk,
			copies: options.copies || 1,
			color_option: options.color_option,
			duplex_option: options.duplex_option,
		};
		if (price! <= currentBalance) {
			const isSuccess = await asyncRetry(makeOrderFromForm, 3)(data);
			if (isSuccess) {
				currentBalance -= price!;
			} else {
				errorDocuments.push(document.name);
			}
			continue;
		}
		orderFormsToPay.push(data);
		currentBalance -= price!;
	}

	if (currentBalance < 0) {
		const toPay = - currentBalance;
		const { confirmation_token } = await createPayment({
			value: toPay,
			url: getPrintOperationsUrl(documentPks),
			orders: orderFormsToPay,
		});
		return { confirmation_token, errorDocuments, toPay };
	}
	return { confirmation_token: null, errorDocuments, toPay: 0 };
}

/**
 * Returns empty object for non-authorized users
 */
export function getUserBackend() {
	return get<UserBackend | EmptyObject>(apiPath.USER.GET);
}

export async function redirectToPbnPay(documentPk: number, printerPk: number) {
	const withCelebrationDiscount = getCelebration(new Date()) ? 1 : 0;
	const { confirmation_token } = await createPayment({
		value: getPriceWithDiscount(BASE_PRICE, BASE_DISCOUNT + withCelebrationDiscount * CELEBRATION_DISCOUNT),
		url: getPrintOperationsUrl([documentPk]),
		orders: [{
			document: documentPk,
			printer: printerPk,
			copies: PAPER_COUNT_FOR_ORDER,
			color_option: OptionsColorType.bw,
			duplex_option: OptionsDoublePageType.singleSide,
		}],
	});
	return confirmation_token;
}

export async function getSupportConversations(page: number, pageSize: number) {
	return (await get<Conversations>(`${apiPath.CONVERSATIONS}?page=${page}&page_size=${pageSize}`)).results;
}
