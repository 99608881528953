import { lazy } from 'react';

import { FirstPage } from 'pages/FirstPage';

export const EmailChangedPopup = lazy(() => import(/* webpackChunkName: "EmailChangedPopup" */'./email-changed-popup'));

export const EmailChangePopupPage = () => (
	<>
		<EmailChangedPopup isOpen={true} />
		<FirstPage />
	</>
);
